.podcast{
    padding-top: 42px;
    height: 700px;
    overflow: hidden;
    margin-bottom: 0;
    background-color: rgb(240, 207, 20);

}


#var{
    padding-top: 42px;
    padding-bottom: 100%;
    background-color: rgb(0, 0, 0);
}
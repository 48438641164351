.NavBar{
   background-color: black;
   color: rgb(228, 226, 226);
}

.NavBarImg{
    background-color: rgb(240, 207, 20);
}




.NavBar{
    font-family:'BenchNine';
    width: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 4;
    box-shadow: 0px 11px 28px -5px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 11px 28px -5px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 11px 28px -5px rgba(0,0,0,0.75);
}

.NavBarImg {
    display: flex;
    margin-top: 40px;
    padding-top: 20px;
    width: 100%;
    position: relative;
    justify-content: center;
    z-index: 2;
   
}

.NavBarImg > img{
    width:400px;
    margin-bottom: 10px;
 
}

.NavBarContainer,.NavBarContainer-mobile{
    display: flex;
    width: 100%;
    justify-content:space-evenly;
}

.NavBarItem{
    font-weight:100;
    margin-top: 2px;
    margin-bottom: 2px;
    transition: all 0.4s;
    cursor: pointer;
}

.NavBarContainer-mobile{
    display: flex;
    flex-direction: column;
    animation-name: buttonAnim;
    animation-duration: 2s;
}

.dropDown{
    display: flex;
    flex-direction: column;
    width: 0px;
    padding: 0%;
    margin: 0%;
    animation-name: dropdownAnim;
    animation-duration: 2s;
}

.NavBarButton{
    width: 50px;
    animation-name: buttonAnim;
    animation-duration: 1s;
}

@keyframes  buttonAnim {
    from {margin-left: 100%;}
    to {bmargin: 0%;}
  }

@keyframes  dropdownAnim {
    0%{
        padding: 0px;
        transform: translateY(-300px);
        opacity: 0;
    }
  }

.NavBarItem:hover{
    transform: scale(1.2);
    margin-top: 10px;
}



@media screen and (max-width: 768px) {
    .NavBar{
        justify-content: space-between;
        flex-direction: row-reverse;
    }

    .NavBarImg {
        overflow: hidden;
    }
    .NavBarImg > img{
        width:50%;
        image-rendering: pixelated;
        min-width: 180px;
        height: auto;
    }

    .NavBarButton{
        margin-top: 3px;
        margin-left: 1%;
    }

}

.imgSliderImg {
    width: 100%;
    height: 500px;
    background-position: center;
    background-size: 80%; /* Change from 100% to cover */
    overflow: hidden;
    background-repeat: repeat;
    box-shadow: 0px 2px 27px -1px rgba(0, 0, 0, 0.69);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
 

.imgSliderContainer{
    display: flex;
    align-self: flex-end;
    margin-bottom: 10px;
    flex-direction: row;
    align-items: center;
}
.flexSlider{
    display: flex;
    flex-direction: column;
    height: 500px;
    width: 100%;
    justify-content: space-between;
}
.flexSlider> h1{
    margin: 0%;
    width: 50%;
    border-radius: 0 0 25px 0;
    font-family: verdana;
    color:rgba(240, 207, 20, 0.007);
    font-size: 11px;

}

.imgSliderImg-button{
    border-radius: 50px 0px 50px 0px;
    border: transparent;
    background-color:black;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;
    transition: all 0.2s;
}
.imgSliderImg-button:hover{
    transform: scale(1.3)
}

@media screen and (max-width: 800px) {

    .imgSliderImg {
        background-size: cover; /* Ensures responsiveness */
        width: 100%;
        height: 200px;
    }
    .flexSlider{
        height: 500px;
    }
    .imgSliderImg-button{
        margin-bottom: 0px;
        padding-left: 7px;
        padding-right: 7px;
        padding-top: 3px;
        margin-left: 2px;
        margin-right: 2px;
    }

    .flexSlider > h1{
        width: 90%;
        height: 10px;
        padding-left: 12px;
        font-size: 7px;
    }
}



.formContainer{
    margin-top: 10px;
    width:600px;
    height: 300px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    background-color: rgb(221, 221, 221);
    min-width: 150px;
}

.form{
    width: 80%;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

.formContainer > h1{
    padding-left: 10px;
    width: 100%;
    margin-top: 0%;
    background-color: rgb(136, 188, 233);
}

.form > button{
    margin-top: 20px;
    border-radius: 25px;
    border: transparent;
    color: white;
    font-size: 28px;
    font-family: 'benchNine';
    background-color: rgb(81, 81, 245);
    transition: all 0.4s;
}

.form > button:hover{
    background-color: rgb(58, 165, 151);
    cursor: pointer;
}

.form > input,.login > label{
    font-family: 'benchNine';
    font-size: 26px;
    font-weight: 400;
}

.form > input{
    border: transparent;
    border-radius: 25px;
    text-align: center;
}

.news{
    display: flex;
}

.mediaContainer{
    display: flex;
    flex-direction: column;
    width: 35%;
    padding: 6px;
    background-color: transparent;
    justify-content: space-evenly;
}

.home_blogContainer{
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-left: 3%;
    padding-top: 50px;
}

.youtubeVideo,.spotify{
    border: transparent;
    margin-top: 20px;
    margin-bottom: 5px;
}

#blogtittle,#revistaquicktittle{
    margin: 0;
    font-size: 40px;
    font-family: benchNine;
    padding-left: 25px;
    position: relative;
    padding-top: 10px;
    color: rgb(240, 207, 20);
    z-index: 2;
    box-shadow: 0px 12px 8px -6px rgba(0,0,0,1);
-webkit-box-shadow: 0px 12px 8px -6px rgba(0,0,0,1);
-moz-box-shadow: 0px 12px 8px -6px rgba(0,0,0,1);
}

#blogtittle{
    color: black;
}

@media screen and (max-width: 1050px) {
    .mediaContainer,.home_blogContainer{
       flex-direction: column;
    }

     .home_blogContainer{
        margin-left: 0%;
        align-items: center;
     }
    .mediaContainer{
        width: 100%;
        padding: 0px;
    }
    .news{
        flex-direction: column;
     
    }
    #blogtittle,#revistaquicktittle{
        font-size: 27px;
    }

    .youtubeVideo{
        align-self: center;
        width: 90%;
        height: 300px;
        margin-top: 10px;
        margin-bottom: 20px;
    }
}
.footerContainer{
    background-color: black;
    position:absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align:justify;
    padding-bottom: 80px;
}

.footerTitle,.footerOtro{
    text-align: center;
    color: rgb(248, 245, 245);
    margin-top: 2%;
    margin-bottom: 2%;
}

.footerInfo{
    color:rgb(151, 151, 151);
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 0%;
}



@media screen and (max-width: 420px) {
    .footerTitle,.footerOtro{
        font-size: 12px;
    }
    .footerInfo{
        font-size: 10px;
    }
}

body::-webkit-scrollbar {
  width:20px;
  background-color: aliceblue;
}
 
body::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.253);
  box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.089);
}
 
body::-webkit-scrollbar-thumb {
  background-color: rgb(0, 0, 0);
  border-radius: 2px;
}

body{
  background:rgb(240, 207, 20);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.noticias {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content:space-around;
    background-color: rgb(240, 207, 20);
  }
  
  .noticiasItem {
    width: 30%;
    margin-top: 2%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    border: 2px solid rgb(197, 170, 16);

  } 
  .noticiasItem:hover{
    cursor: pointer;
  }

  .categoria{
    margin-top: 10px;
    margin-left: 5%;
    width: max-content;
    font-weight: bolder;
    font-family:'benchNine';
    padding-bottom: 1px;
    border-bottom: 2px solid black;
  }
  .noticiasItem__img {
    height: 200px;
    max-width: 100%;
    overflow: hidden;
  } 

  .noticiasItem__img img{
    height: auto;
    max-width: 100%;
    align-items: center;
  } 

  .noticiasItem__titulo{
    text-align: center;
    margin-bottom: 0;
  }
  
  .noticiasItem__descripcion,.noticiasItem__autor{
    font-weight: normal;
    font-size: small;
    font-family: "Georgia Regular", Sans-serif;
    margin-left: 5%;
    max-height: 50px;
    overflow-y: hidden;
    color: rgba(0, 0, 0, 0.801);
    overflow-wrap: break-word;
  }
  .noticiasItem__autor{
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(56, 56, 56);
  }

  @media (max-width: 918px) {
    .noticiasItem {
        width: 275px;
      } 
  }
.blogadmin-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    padding-top:  20px;
    padding-bottom:20px;
}

/* Styles for the form container */
.form-container {
    max-width: 450px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    margin-top: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Styles for form fields */
  .form-field {
    margin-bottom: 20px;
  }
  
  /* Styles for labels */
  .label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  /* Styles for input fields */
  .input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  /* Styles for the submit button */
  .submit-button,.cancel-button,.add-button  {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
  }

  .cancel-button {
    background-color: #ff0000;
    margin-left:10px;
  }

  .add-button {
    background-color: #12970d;
  }

  .noticia{
    padding-bottom: 5px;
    border-bottom: black 5px solid;
  }
  
  /* Responsive styles */
  @media (max-width: 600px) {
    /* Adjust form container width for smaller screens */
    .form-container {
      max-width: 100%;
      padding: 10px;
    }
  }
  
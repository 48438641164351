.loading-loop {
  display: flex;
  font-family:'benchNine';
  font-size: larger;
  font-weight: bold;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  
}

.loading-spinner {
  border: 4px solid #3498db;
  border-top-color: #e74c3c;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}